import FirstSectionImage from '../../../Assets/Images/FirstSectionImage.png'

const FirstSection = () => {
  return (
    <div className='h-full lg:min-h-screen pt-28 md:pt-32 pb-12 md:pb-16 px-4 md:px-6 lg:px-12'>
      <div className='mt-6 ml-6 md:ml-8 lg:ml-12 place-items-center grid grid-cols-1 md:grid-cols-2 gap-4'>
        <div className='flex-rows items-center justify-center pr-4 lg:pr-12'>
          <h1 className='mt-12 text-3xl lg:text-4xl font-Poppins font-bold'>Decentralized analytics platform</h1>
          <p className='mt-10 font-Poppins text-base md:text-lg lg:text-xl mr-8 md:mr-4 lg:mr-32'>
            Sharanga is an AI-powered privacy preserving distributed analytics infrastructure. Our system accelerates
            collaborative research and provides precise and advanced insights to improve and individualise solutions,
            without ever having to exposing your data
          </p>
        </div>
        <div className='hidden md:block m-auto'>
          <img className='h-full lg:h-4/5 w-full lg:w-4/5' src={FirstSectionImage} alt='FirstSectionImage' />
        </div>
      </div>
    </div>
  )
}

export default FirstSection
