import Navbar from './Components/NavBar'
import Footer from './Components/Footer'
import HomePage from './Pages/HomePage'

function App () {
  return (
    <div className='App'>
      <Navbar />
      <HomePage />
      <Footer />
    </div>
  )
}

export default App
