import contactus from '../../../Assets/Images/ComingSoon.png'

const FourthSection = () => {
    return (
      <div className='h-full pt-0 md:pt-12 pb-12 md:pb-16 px-4 md:px-6 lg:px-12'>
        <div className='mt-6 ml-0 md:ml-8 lg:ml-12 place-items-center grid grid-cols-1 md:grid-cols-2 gap-4'>
          <div className='hidden md:block m-auto'>
            <img className='h-full w-full' src={contactus} alt='FirstSectionImage' />
          </div>
          <div className='flex-rows items-center justify-center pr-4 lg:pr-20'>
            <h1 className='mt-6 text-xl lg:text-4xl font-Poppins font-bold mb-2'>Want to stay in touch with us?</h1>
            <button onClick={() => window.open('https://forms.gle/8A71zmGaqHUvMNU79')} className='rounded-md font-bold bg-customBlueTwo text-white text-2xl px-4 py-1'>
                Click Here
            </button>
          </div>
        </div>
      </div>
    )
}

export default FourthSection