import secondSectionImage1 from '../../../Assets/Images/SecondSectionImage.png'
import secondSectionImage2 from '../../../Assets/Images/SecondSectionImage2.png'
import secondSectionImage3 from '../../../Assets/Images/SecondSectionImage3.png'

const SecondSection = () => {
  return (
    <div className='pt-6'>
      <h1 className='mx-8 md:mx-0 font-Poppins text-3xl md:text-4xl text-center font-bold mb-4 md:mb-12 lg:mb-20 2xl:mb-32'>Sharanga enables users to</h1>
      <div className='flex justify-center items-center'>
        <div class='mb-16 mr-2 md:mr-10 grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-8 content-center'>
          <div className='mt-12 w-80 md:w-96 mx-auto md:mx-10'>
            <img className='mx-auto mb-8' src={secondSectionImage1} alt='secondSectionImage1' />
            <h1 className='text-center font-Poppins lg:text-base md:text-xl font-normal'>To extract local industrial insights</h1>
          </div>
          <div className='mt-12 w-80 md:w-96 mx-5 md:mx-10'>
            <img className='mx-auto mb-14' src={secondSectionImage2} alt='secondSectionImage1' />
            <h1 className='text-center font-Poppins lg:text-base md:text-xl font-normal'>Enhance learning and predictive results and reduce bias</h1>
          </div>
          <div className='mt-12 w-80 md:w-96 mx-5 md:mx-10'>
            <img className='mx-auto mb-16' src={secondSectionImage3} alt='secondSectionImage1' />
            <h1 className='text-center font-Poppins lg:text-base md:text-xl font-normal'>Barter them with a network of organizations without the data having to leave its source of origin</h1>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SecondSection
