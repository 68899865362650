import {useEffect} from 'react'

import FirstSection from './FirstSection'
import SecondSection from './SecondSection'
import FourthSection from './FourthSection'

const HomePage = () => {
  
  useEffect(() => {
    window.scrollTo(0, 0)
    document.title = 'Sharanga'
  }, [])

  return (
    <div>
      <FirstSection />
      <SecondSection />
      <FourthSection />
    </div>
  )
}

export default HomePage
